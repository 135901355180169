export interface UserModel {
    items: Array<UserData>,
    totalItemCount: number,
    pageCount: number,
    pageSize: number,
    pageNumber: number

}
export interface UserData {
    id: string,
    fullName: string,
    firstName?: any;
    lastName?: any;
    email: string,
    coreProCustomerId?: any,
    status: number,
    deviceType?: any,
    appVersion?: any,
    createdAt: Date,
    lastModifiedAt?: Date
}

export interface UserSearch {
    pageNumber: number,
    pageSize: number,
    searchValue: string,
    orderByDescending: boolean,
    sortColumn: string,
    status: number | null,
    createdAt: DateFilter
    loginAt: DateFilter
}

export interface DateFilter {
    from: any,
    to: any
}

export interface UserSuggestionsModel {
    fullName: string,
    email: string,
    id: string
}

export enum UserStatus {
    Active,
    BankDisconnected,
    BankConnected,
    DeletedRequest,
    NotVerified,
    OnBoarding,
    NotIdentified, 
    Deleted
}

export enum UserStatusNotification {
    Active, 
    BankDisconnected, 
    BankConnected,
    DeletedRequest,
    NotVerified, 
    OnBoarding,
    NotIdentified, 
    Deleted, 
    MainInvestInvestors, 
    SubscriptionDowngrade
}

export interface DeviceUsersModel {
    deviceId: string;
    count: number;
    users: UserData[];
}
export interface DeciceIdsUsersModel {
    deviceId: string;
    count: number;
    users: UserData[];
}
export interface PaginatedDeciceIdsUsersModel {
    items: DeciceIdsUsersModel[];
    totalItemCount: number;
    pageCount: number;
    pageSize: number;
    pageNumber: number;
}
export interface GetListOfDevices {
    pageNumber: number;
    pageSize: number;
    orderDescending: boolean;
    searchFor: string | null;
}


